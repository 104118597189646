<template>
  <v-app>
        <transition>
          <router-view></router-view>
        </transition>
  </v-app>

</template>
<script>
export default {
  name: "Layout",
  data() {
    return {
      overlay: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
    };
  },
};
</script>
